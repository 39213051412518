import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { FORGOT_PASSWORD_API } from "../../apiConfig/ApiConfig";
import Header from "../../components/Header";

const ForgotPW = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(FORGOT_PASSWORD_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: values.email }),
      });

      if (response.ok) {
        setAlertSeverity("success");
        setAlertMessage("Password reset email sent successfully.");
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
          navigate("/OTP");
        }, 2000);
      } else {
        const errorData = await response.json();
        setAlertSeverity("error");
        setAlertMessage(
          errorData.message ||
            "Failed to send password reset email. Please try again."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to send password reset email. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="Forgot Password" subtitle="Enter your email address" />

      <Box justifyContent="center" margin="20px">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="email"
                    label="Enter Your Email Address *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="email"
                    label="Confirm Your Email Address *"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmEmail}
                    name="confirmEmail"
                    error={!!touched.confirmEmail && !!errors.confirmEmail}
                    helperText={touched.confirmEmail && errors.confirmEmail}
                  />
                </Box>
                <Box display="flex" justifyContent="left" mt={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: loading ? "#b0b0b0" : "#6870fa",
                      color: "white",
                      fontSize: "12px",
                      "&:hover": {
                        backgroundColor: loading ? "#9e9e9e" : "#3e4396",
                      },
                    }}
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send Reset Email"}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "Emails must match")
    .required("Please confirm your email address"),
});

const initialValues = {
  email: "",
  confirmEmail: "",
};

export default ForgotPW;
