import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const Index = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://back.wwwebdevelopment.com/project/getAllProjects"
        );
        if (response.data.status) {
          setProjects(response.data.result);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  const handleEdit = (id) => {
    navigate(`/editProject/${id}`);
  };

  const handleAdd = () => {
    navigate(`/addProject`);
  };

  const handleDelete = async (projectId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to recover this project!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(
          `https://back.wwwebdevelopment.com/project/deleteProject/${projectId}`
        );
        Swal.fire("Deleted!", "Your project has been deleted.", "success");

        setProjects(projects.filter((project) => project.id !== projectId));
      } catch (error) {
        Swal.fire("Error!", "Failed to delete the project.", "error");
        console.error("Failed to delete project:", error);
      }
    }
  };

  return (
    <Box m="20px" height="70vh" overflow="auto">
      <Header title="Projects" subtitle="View all data about projects" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          m: 2,
          mt: -5,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAdd}
          sx={{
            fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "14px",
            padding: isSmallScreen ? "8px 16px" : "10px 20px",
          }}
        >
          Add New Project
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" gap="20px">
        {projects.map((project) => (
          <Card
            key={project.id}
            sx={{
              display: "flex",
              gap: 2,
              padding: "16px",
              position: "relative",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: isSmallScreen ? 120 : isMediumScreen ? 140 : 160,
                height: isSmallScreen ? 130 : isMediumScreen ? 140 : 150,
              }}
              image={project.Image}
              alt={project.topic}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography
                variant="h5"
                marginBottom={"10px"}
                sx={{
                  fontSize: isSmallScreen
                    ? "14px"
                    : isMediumScreen
                    ? "16px"
                    : "20px",
                }}
              >
                ID: {project.id}
              </Typography>
              <Typography
                variant="h5"
                marginBottom={"5px"}
                sx={{
                  fontSize: isSmallScreen
                    ? "16px"
                    : isMediumScreen
                    ? "18px"
                    : "20px",
                }}
              >
                Topic: {project.topic}
              </Typography>
              <Typography
                variant="body1"
                marginBottom={"3px"}
                sx={{
                  fontSize: isSmallScreen
                    ? "14px"
                    : isMediumScreen
                    ? "16px"
                    : "18px",
                }}
              >
                Description: {project.description}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: isSmallScreen
                    ? "12px"
                    : isMediumScreen
                    ? "14px"
                    : "16px",
                }}
              >
                Created At:{" "}
                {moment(project.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </CardContent>
            <Box
              position={isSmallScreen ? "static" : "absolute"}
              right="16px"
              bottom="16px"
              display="flex"
              gap="5px"
            >
              <IconButton
                color="primary"
                onClick={() => handleEdit(project.id)}
                sx={{
                  fontSize: isSmallScreen ? "20px" : "24px",
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => handleDelete(project.id)}
                sx={{
                  fontSize: isSmallScreen ? "20px" : "24px",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Index;
