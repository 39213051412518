// src/api/apiConfig.js
import axios from "axios";

const API_BASE_URL = "https://back.wwwebdevelopment.com";

// const API_BASE_URL = "http://localhost:8000";

// const API_BASE_URL = "http://localhost:8000";
const BASE_URL = `${API_BASE_URL}/admin`;

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getFrontDetails = async (id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/fontDetails/getFrontDetails/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch front details:", error);
    throw error;
  }
};

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Function to login
export const login = async (formData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Login failed");
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message || "An error occurred during login");
  }
};

// Functions to reset pw
export const FORGOT_PASSWORD_API = `${BASE_URL}/forgotPassword`;

export const OTP_API = `${BASE_URL}/verifyOtp`;

export const REST_PW_API = `${BASE_URL}/resetPassword`;

// Function to change admin email
export const changeEmail = (oldPassword, newPassword) => {
  const oldEmail = oldPassword;
  const newEmail = newPassword;

  return apiClient.post("/ChangeEmail", {
    oldEmail,
    newEmail,
  });
};

// Function to change password
export const changePassword = (oldPassword, newPassword) => {
  return apiClient.post("/ChangePassword/1", {
    oldPassword,
    newPassword,
  });
};

//get home page top data
export const getCompanyDetails = async (id) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/companyDetails/getCompanyDetailsById/${id}`
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to fetch company details");
    }

    if (!data.result) {
      return null;
    }

    return data.result;
  } catch (error) {
    console.error("API Error:", error);
    throw new Error(
      error.message || "An error occurred while fetching company details"
    );
  }
};

//Get all Cards
export const getAllCards = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/cardDetails/getAllCards`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to fetch card details");
    }

    return data.result;
  } catch (error) {
    // console.error("API Error:", error);
    throw new Error(
      error.message || "An error occurred while fetching card details"
    );
  }
};

// Function to add a new card
export const addCard = async (cardData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/cardDetails/addCard`,
      cardData
    );
    return response.data;
  } catch (error) {
    throw new Error(`Failed to add card: ${error.message}`);
  }
};

//Add new project

//delete card
export const deleteCard = async (cardId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/cardDetails/deleteCard/${cardId}`,
      {
        method: "DELETE",
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to delete card:", error);
    throw error;
  }
};

//Get card by ID
export const getCardById = async (cardId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/cardDetails/getCardById/${cardId}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.error("Failed to fetch card details:", error);
    throw error;
  }
};

// Function to update a card
export const updateCard = async (id, formData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/cardDetails/updateCard/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error updating card: ${error.message}`);
  }
};

export const updateCompanyDetails = async (id, data) => {
  const response = await fetch(
    `${API_BASE_URL}/companyDetails/updateCompanyDetails/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) throw new Error("Failed to update company details");
  return response.json();
};

export const updateMainTopicDetails = async (id, data) => {
  const response = await fetch(
    `${API_BASE_URL}/fontDetails/updateFrontDetails/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) throw new Error("Failed to update company details");
  return response.json();
};

//get about us
export const getAboutUsById = async (id) => {
  const response = await axios.get(
    `${API_BASE_URL}/aboutUs/getAboutUsById/${id}`
  );
  return response.data;
};

// Update about us by ID
export const updateAboutUsById = async (id, formData) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/aboutUs/updateAboutUsByID/${id}`,
      {
        method: "PUT",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating About Us:", error);
    throw error;
  }
};

//get by id contact-us
export const getContactUsData = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/conactUs/getContactUsById/1`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching contact us data:", error);
    throw error;
  }
};

export const fetchContactDetails = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getContactUsById`);
    return response.data;
  } catch (error) {
    console.error("Error fetching contact details:", error);
    throw error;
  }
};

export const getContactDetailsById = async (id) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/conactUs/getContactUsById/${id}`
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to fetch contact details");
    }

    return data.result;
  } catch (error) {
    console.error("API Error:", error);
    throw new Error(
      error.message || "An error occurred while fetching contact details"
    );
  }
};

export const updateContactDetails = async (id, details) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/conactUs/updateContactUs/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to update contact details");
    }

    return data;
  } catch (error) {
    console.error("API Error:", error);
    throw new Error(
      error.message || "An error occurred while updating contact details"
    );
  }
};

//add about us
export const addAboutUs = async (formData) => {
  const response = await fetch(`${API_BASE_URL}/aboutUs/addAboutUs`, {
    method: "POST",
    body: formData,
  });
  const data = await response.json();
  return data;
};

//add contact us
export const createContactUs = async (contactData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/conactUs/createContactUs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating contact:", error);
    throw error;
  }
};

export const createMainHeader = async (details) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/fontDetails/createFrontDetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Failed to create main header details"
      );
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw new Error(
      error.message || "An error occurred while creating main header details"
    );
  }
};

//Create company details
export const createCompanyDetails = async (details) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/companyDetails/createCompanyDetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to create company details");
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error);
    throw new Error(
      error.message || "An error occurred while creating company details"
    );
  }
};
export default apiClient;
