import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="right"
          gap="20px"
        >
          <Button
            component={Link}
            to="/changepw"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "10px",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Change Admin Password
          </Button>
          <Button
            component={Link}
            to="/changeemail"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "10px",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Change Admin Email
          </Button>
        </Box>{" "}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", margin: "20px" }}>
        <Button
          component={Link}
          to="/home"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "10px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <HomeOutlinedIcon sx={{ mr: "10px" }} />
          Home
        </Button>

        <Button
          component={Link}
          to="/about"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "10px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <InfoOutlinedIcon sx={{ mr: "10px" }} />
          About Us
        </Button>

        <Button
          component={Link}
          to="/contact"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContactMailOutlinedIcon sx={{ mr: "10px" }} />
          Contact Us
        </Button>

        <Button
          component={Link}
          to="/projects"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <ContactMailOutlinedIcon sx={{ mr: "10px" }} />
          Projects
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
