import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ChangeEmail from "./scenes/Auth/Changeemail.jsx";
import ChangePassword from "./scenes/Auth/Changepw.jsx";
import Forgotpw from "./scenes/Auth/Forgotpw.jsx";
import OTP from "./scenes/Auth/OTP.jsx";
import ResetPW from "./scenes/Auth/Resetpw.jsx";
import SignInSide from "./scenes/Auth/Singup.jsx";
import Contacts from "./scenes/contacts";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Form from "./scenes/form";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import AddAboutUs from "./scenes/pages/AboutUs/Addaboutus.jsx";
import Editaboutus from "./scenes/pages/AboutUs/Editaboutus.jsx";
import About from "./scenes/pages/AboutUs/index.jsx";
import AddContact from "./scenes/pages/ContactUs/AddCotntact.jsx";
import EditContact from "./scenes/pages/ContactUs/EditContactUs.jsx";
import Contact from "./scenes/pages/ContactUs/index.jsx";
import AddMianTopic from "./scenes/pages/Home/AddMainTopic.jsx";
import AddTopic from "./scenes/pages/Home/AddTopics.jsx";
import EditMainTopics from "./scenes/pages/Home/EditMainTopic.jsx";
import EditTopics from "./scenes/pages/Home/EditTopics.jsx";
import CreateCard from "./scenes/pages/Home/card/Createcard.jsx";
import EditCard from "./scenes/pages/Home/card/Editcard.jsx";
import CardView from "./scenes/pages/Home/card/ViewCard.jsx";
import Home from "./scenes/pages/Home/index.jsx";
import AddNewProject from "./scenes/projects/AddNewProject.jsx";
import EditProject from "./scenes/projects/EditProject.jsx";
import Index from "./scenes/projects/index.jsx";
import Team from "./scenes/team";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoutes from "./utils/PrivateRoutes.jsx";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("accessToken");
    };

    window.addEventListener("beforeunload", clearLocalStorage);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<SignInSide />} />
              <Route path="/changepw" element={<ChangePassword />} />
              <Route path="/forgotpw" element={<Forgotpw />} />
              <Route path="/changeemail" element={<ChangeEmail />} />
              <Route path="/OTP" element={<OTP />} />
              <Route path="/reset-pw" element={<ResetPW />} />
              {/* Private routes */}
              <Route element={<PrivateRoutes />}>
                <Route path="/dd" element={<Dashboard />} />
                <Route path="/team" element={<Team />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/form" element={<Form />} />
                <Route path="/home" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/contact/editContact" element={<EditContact />} />
                <Route path="/contact/addContact" element={<AddContact />} />
                <Route path="/about" element={<About />} />
                <Route path="/card/create" element={<CreateCard />} />
                <Route path="/card/view/:id" element={<CardView />} />
                <Route path="/card/edit/:id" element={<EditCard />} />
                <Route path="/home/editTopics" element={<EditTopics />} />
                <Route
                  path="/home/editMainDetails"
                  element={<EditMainTopics />}
                />
                <Route path="/home/addMainDetails" element={<AddMianTopic />} />
                <Route path="/home/addTopics" element={<AddTopic />} />
                <Route path="/aboutus/editAbout" element={<Editaboutus />} />
                <Route path="/aboutus/addAbout" element={<AddAboutUs />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/projects" element={<Index />} />
                <Route path="/addProject" element={<AddNewProject />} />
                <Route path="/editProject/:id" element={<EditProject />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
