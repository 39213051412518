import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/Header";

const EditAboutUs = ({ existingData = {} }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [image1, setImage1] = useState(existingData.image1 || null);
  const [image1Preview, setImage1Preview] = useState(
    existingData.image1 ? URL.createObjectURL(existingData.image1) : ""
  );
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    topic: existingData.topic || "",
    description: existingData.description || "",
    image1: existingData.image1 || null,
  };

  const validationSchema = yup.object().shape({
    topic: yup.string().required("Topic is Required"),
    description: yup.string().required("Description is Required"),
    image1: yup
      .mixed()
      .required("Image is required")
      .test("fileType", "Unsupported file format", (value) => {
        return value && ["image/jpeg", "image/png"].includes(value.type);
      })
      .test("fileSize", "Image too large", (value) => {
        return value && value.size <= 5 * 1024 * 1024;
      }),
  });

  const handleFormSubmit = async (values, { setErrors }) => {
    setLoading(true);

    if (!values.image1) {
      setErrors({ image1: "Image 1 is required" });
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("topic", values.topic);
      formData.append("description", values.description);
      formData.append("image", image1);

      const response = await fetch(
        "https://back.wwwebdevelopment.com/project/addProject",
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        setAlertSeverity("success");
        setAlertMessage("Successfully created project.");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/projects");
        }, 2500);
      } else {
        setAlertSeverity("error");
        setAlertMessage(responseData.message || "Failed to create project.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error creating project:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to create project.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px" height="75vh" overflow="auto">
      <Header title="ADD PROJECT" subtitle="Create a new project" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Topic"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.topic}
                name="topic"
                error={!!touched.topic && !!errors.topic}
                helperText={touched.topic && errors.topic}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />

              {/* Image Upload */}
              <input
                type="file"
                accept="image/*"
                id="image-upload-1"
                style={{ display: "none" }}
                onChange={(event) => {
                  const file = event.currentTarget.files[0];
                  setImage1(file);
                  setImage1Preview(URL.createObjectURL(file));
                  setFieldValue("image1", file);
                }}
              />
              <label htmlFor="image-upload-1">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Typography sx={{ marginRight: "20px" }}>
                    Upload Image
                  </Typography>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{ border: "1px solid #ddd", borderRadius: "4px" }}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </Box>
                {image1Preview && (
                  <Box>
                    <img
                      src={image1Preview}
                      alt="Image 1 Preview"
                      style={{
                        width: "50%",
                        height: "150px",
                        border: "1px solid #000",
                      }}
                    />
                  </Box>
                )}
                {touched.image1 && errors.image1 && (
                  <Typography color="error" variant="body2">
                    {errors.image1}
                  </Typography>
                )}
              </label>
            </Box>
            <Box display="flex" justifyContent="end" m="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Add Project"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditAboutUs;
